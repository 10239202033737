import { BannerImage,BannerImage2,BannerImage33,BannerMobile,MorraGame1,MorraGame2,LatestItem1,LatestItem2,LatestItem3,LatestItem4,BestSelling1,BestSelling2,BestSelling3,BestSelling4,Trending1,Trending2,Trending3,Trending4,ComingSoon1,ComingSoon2,ComingSoon3,ComingSoon4,ListingBanner,ListingBannerMob } from "../Imports/ImportImages"

// JSON FOR MORRA GAME PRODUCT

export const morraGameProduct = [
    {
        id : 1,
        img : MorraGame1,
        title : 'Karnak Legacy',
        // sale : 'sale',
        subTitle : 'Game',
        // price : '2.990',
    },
    {
        id : 2,
        img : MorraGame1,
        title : 'Scifi soldiers in a futuristic 2024',
        // sale : 'sale',
        subTitle : 'Game',
        // price : '2.990',
    },
]

// JSON FOR PRODUCT LISTING

export const productListing = [
    {
        id : 1,
        img : LatestItem1,
        title : 'Garden City',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
        
    },
    {
        id : 2,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 3,
        img : LatestItem3,
        title : 'Garden City',
        salePrice: '$2.500',
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 4,
        img : LatestItem4,
        title : 'Tools Up! Ultimate Edition',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 5,
        img : LatestItem1,
        title : 'Garden City',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 6,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 7,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 8,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
]

export const productListing2 = [
    {
        id : 1,
        img : LatestItem1,
        title : 'Garden City',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
        
    },
    {
        id : 2,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 3,
        img : LatestItem3,
        title : 'Garden City',
        salePrice: '$2.500',
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 4,
        img : LatestItem4,
        title : 'Tools Up! Ultimate Edition',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 5,
        img : LatestItem1,
        title : 'Garden City',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 6,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 7,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 8,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 9,
        img : LatestItem1,
        title : 'Garden City',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
        
    },
    {
        id : 10,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 11,
        img : LatestItem3,
        title : 'Garden City',
        salePrice: '$2.500',
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 12,
        img : LatestItem4,
        title : 'Tools Up! Ultimate Edition',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 13,
        img : LatestItem1,
        title : 'Garden City',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 14,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 15,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 16,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 17,
        img : LatestItem1,
        title : 'Garden City',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
        
    },
    {
        id : 18,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 19,
        img : LatestItem3,
        title : 'Garden City',
        salePrice: '$2.500',
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 20,
        img : LatestItem4,
        title : 'Tools Up! Ultimate Edition',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 21,
        img : LatestItem1,
        title : 'Garden City',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 22,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 23,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 24,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 25,
        img : LatestItem1,
        title : 'Garden City',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
        
    },
    {
        id : 26,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 27,
        img : LatestItem3,
        title : 'Garden City',
        salePrice: '$2.500',
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 28,
        img : LatestItem4,
        title : 'Tools Up! Ultimate Edition',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 29,
        img : LatestItem1,
        title : 'Garden City',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 30,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 31,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 32,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 33,
        img : LatestItem1,
        title : 'Garden City',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
        
    },
    {
        id : 34,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 35,
        img : LatestItem3,
        title : 'Garden City',
        salePrice: '$2.500',
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 36,
        img : LatestItem4,
        title : 'Tools Up! Ultimate Edition',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 37,
        img : LatestItem1,
        title : 'Garden City',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 38,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 39,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 40,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 41,
        img : LatestItem1,
        title : 'Garden City',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
        
    },
    {
        id : 42,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 43,
        img : LatestItem3,
        title : 'Garden City',
        salePrice: '$2.500',
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 44,
        img : LatestItem4,
        title : 'Tools Up! Ultimate Edition',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 45,
        img : LatestItem1,
        title : 'Garden City',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 46,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 47,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 48,
        img : LatestItem2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
   
]

// JSON FOR bestSelling SECTION

export const bestSelling = [
    {
        id : 1,
        img : BestSelling1,
        title : 'Garden City',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 2,
        img : BestSelling2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 3,
        img : BestSelling3,
        title : 'Garden City',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 4,
        img : BestSelling4,
        title : 'Tools Up! Ultimate Edition',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 5,
        img : BestSelling1,
        title : 'Javel Runaway 2024',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 6,
        img : BestSelling2,
        title : 'Tools Up! Ultimate Edition',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 7,
        img : BestSelling1,
        title : 'Javel Runaway 2024',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 8,
        img : BestSelling2,
        title : 'Tools Up! Ultimate Edition',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
]

// JSON FOR TRENDING SECTION

export const Trending = [
    {
        id : 1,
        img : Trending1,
        title : 'Garden City',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 2,
        img : Trending2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 3,
        img : Trending3,
        title : 'Garden City',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 4,
        img : Trending4,
        title : 'Tools Up! Ultimate Edition',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 5,
        img : Trending1,
        title : 'Javel Runaway 2024',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 6,
        img : Trending2,
        title : 'Tools Up! Ultimate Edition',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 7,
        img : Trending1,
        title : 'Javel Runaway 2024',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 8,
        img : Trending2,
        title : 'Tools Up! Ultimate Edition',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
]

// JSON FOR COMING SOON SECTION

export const comingSoon = [
    {
        id : 1,
        img : ComingSoon1,
        title : 'Garden City',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 2,
        img : ComingSoon2,
        title : 'Javel Run',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 3,
        img : ComingSoon3,
        title : 'Garden City',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '$ 2.990',
    },
    {
        id : 4,
        img : ComingSoon4,
        title : 'Tools Up! Ultimate Edition',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 5,
        img : ComingSoon1,
        title : 'Javel Runaway 2024',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 6,
        img : ComingSoon2,
        title : 'Tools Up! Ultimate Edition',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 7,
        img : ComingSoon1,
        title : 'Javel Runaway 2024',
        salePrice: "$1.500",
        subTitle : 'Assets',
        price : '2.990',
    },
    {
        id : 8,
        img : ComingSoon2,
        title : 'Tools Up! Ultimate Edition',
        salePrice: null,
        subTitle : 'Assets',
        price : '2.990',
    },
]

// JSON FOR BANNER SLIDES SECTION

export const slidesData = [
    {
      id: 1,
      title: 'Best games to play right now',
      desc: 'These must play video games include open world, exploration, shooters, survival and more!',
      imageUrl: BannerImage33,
      imageUrlMob : BannerMobile,
    }, 
    {
      id: 2,
      title: 'Best games to play right now',
      desc: 'These must play video games include open world, exploration, shooters, survival and more!',
      imageUrl: BannerImage2,
      imageUrlMob : BannerMobile,
    }, {
      id: 3,
      title: 'Best games to play right now',
      desc: 'These must play video games include open world, exploration, shooters, survival and more!',
      imageUrl: BannerImage33,
      imageUrlMob : BannerMobile,
    }, {
      id: 4,
      title: 'Best games to play right now',
      desc: 'These must play video games include open world, exploration, shooters, survival and more!',
      imageUrl: BannerImage2,
      imageUrlMob : BannerMobile,
    }, {
      id: 5,
      title: 'Best games to play right now',
      desc: 'These must play video games include open world, exploration, shooters, survival and more!',
      imageUrl: BannerImage33,
      imageUrlMob : BannerMobile,
    }, {
      id: 6,
      title: 'Best games to play right now',
      desc: 'These must play video games include open world, exploration, shooters, survival and more!',
      imageUrl: BannerImage2,
      imageUrlMob : BannerMobile,
    },
  ];

  export const ProductSlidesData = [
    {
      id: 1,
      imageUrl: BannerImage,
      imageUrlMob : BannerMobile,
    }, 
    {
      id: 2,
      imageUrl: BannerImage2,
      imageUrlMob : BannerMobile,
    }, 
    {
      id: 3,
      imageUrl: BannerImage,
      imageUrlMob : BannerMobile,
    }, 
    {
      id: 4,
      imageUrl: BannerImage2,
      imageUrlMob : BannerMobile,
    }, 
    {
      id: 5,
      imageUrl: BannerImage,
      imageUrlMob : BannerMobile,
    }, 
    {
      id: 6,
      imageUrl: BannerImage2,
      imageUrlMob : BannerMobile,
    },
  ];

  export const ListingSlidesData = [
    {
      id: 1,
      title: 'Jewel Run 2024',
      desc: 'The point of using Lorem Ipsum is that it has a more-or-less normal distribution',
      imageUrl: ListingBanner,
      imageUrlMob : ListingBannerMob,
    }, 
    {
      id: 2,
      title: 'Jewel Run 2024',
      desc: 'The point of using Lorem Ipsum is that it has a more-or-less normal distribution',
      imageUrl: BannerImage2,
      imageUrlMob : ListingBannerMob,
    }, {
      id: 3,
      title: 'Jewel Run 2024',
      desc: 'The point of using Lorem Ipsum is that it has a more-or-less normal distribution',
      imageUrl: ListingBanner,
      imageUrlMob : ListingBannerMob,
    }, {
      id: 4,
      title: 'Jewel Run 2024',
      desc: 'The point of using Lorem Ipsum is that it has a more-or-less normal distribution',
      imageUrl: BannerImage2,
      imageUrlMob : ListingBannerMob,
    }, 
    {
      id: 5,
      title: 'Jewel Run 2024',
      desc: 'The point of using Lorem Ipsum is that it has a more-or-less normal distribution',
      imageUrl: ListingBanner,
      imageUrlMob : ListingBannerMob,
    }, 
    {
      id: 6,
      title: 'Jewel Run 2024',
      desc: 'The point of using Lorem Ipsum is that it has a more-or-less normal distribution',
      imageUrl: BannerImage2,
      imageUrlMob : ListingBannerMob,
    },
  ];


  export const offers = [
    {
        id : 0,
        price : '0.6 WETH',
        usdPrice : '1,513.66',
        quanity : 1,
        floordifL : '13% below',
        expiration : 'in 12 hours',
        from : '8D9D49'
    },
    {
        id : 1,
        price : '0.6 WETH',
        usdPrice : '1,513.66',
        quanity : 1,
        floordifL : '13% below',
        expiration : 'in 12 hours',
        from : '8D9D49'
    },
    {
        id : 2,
        price : '0.6 WETH',
        usdPrice : '1,513.66',
        quanity : 1,
        floordifL : '13% below',
        expiration : 'in 12 hours',
        from : '8D9D49'
    },
    {
        id : 3,
        price : '0.6 WETH',
        usdPrice : '1,513.66',
        quanity : 1,
        floordifL : '13% below',
        expiration : 'in 12 hours',
        from : '8D9D49'
    },
    {
        id : 4,
        price : '0.6 WETH',
        usdPrice : '1,513.66',
        quanity : 1,
        floordifL : '13% below',
        expiration : 'in 12 hours',
        from : '8D9D49'
    }
]